

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafbd7;
  text-decoration: none;
  padding-right: 12px;
  padding-bottom: 3px;
}


a.Dropdown-link {
  color: darkolivegreen;
  text-decoration: none;
}

h1, h2 {
  color: teal
}

a:hover {
  color: #61dafb;
  text-decoration: underline;
}

a.Dropdown-link:hover {
  color: lightseagreen;
  text-decoration: underline;
}

.hatter {
  min-height: 100vh;
  background: url(http://otr.nhely.hu/fuzet.png) no-repeat center center fixed;
  background-size: cover;
  
}

header {
  background-color: #e2ecee;
}

.lekerekitett {
  border-radius: 10px;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
}
